import React from 'react';
import './ImportantWork.css';
import image1 from '../images/IMG_6355.JPEG'
import image2 from '../images/IMG_1392.JPEG'

const ImportantWork = () => {
  return (
    <div className="important-work" id="important-work">
      <div className='important-work-wrapper'>
        <div className='important-work-item'>
          <img src={image2} alt="Important work" />
          <div className="work-description">
            <h2>Extensions</h2>
            <p>Property extensions are an effective way of adding space to your home without the difficulty and wasted expense of moving.
              Extensions add to the value of a property, and can return fully on the investment cost, whilst improving enjoyment of the property space.
            </p>
            <p>Key Developments works closely with talented architechs that can design creative ways to expand your home. The process is managed fully, leaving you to enjoy freedom from the stress of construction.</p>
            <button className='important-work-button'>Contact us about extensions</button>
          </div>
        </div>
        <div className='important-work-item'>
          <img src={image1} alt="Important work" />
          <div className="work-description">
            <h2>Home Refurbishment</h2>
            <p>Moving home is stressful, the idea of managing a renovation project immediately afterwards can be daunting.
              Contacts need to be found for trustworthy, reliable tradesmen who product high-quality work. Schedules need to be drawn up with constant amendment and oversight.
            </p>
            <p>Relieve yourself of the pressure, and bring in an expert. Key Developments manage refurbishments for you, so that your project can be completed seamlessly, to highest standard.</p>
            <button className='important-work-button'>Contact us about refurbishments</button>
          </div>
        </div>

      </div>
    </div>
  );
};

export default ImportantWork;
