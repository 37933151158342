import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './ContactForm.css';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: 'Renovations', // Default value for subject
    message: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Replace these values with your EmailJS service ID, template ID, and user ID
    const serviceID = 'service_ij3qexq';
    const templateID = 'template_zkfj71m';
    const userID = '5ftUOzKRp6aMdBBaR';

    emailjs.send(serviceID, templateID, formData, userID)
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        alert('Your message has been sent successfully!');
      }, (err) => {
        console.error('FAILED...', err);
        alert('Failed to send your message. Please try again.');
      });

    setFormData({
      name: '',
      number: '',
      email: '',
      subject: 'Renovations', // Reset to default value
      message: '',
    });
  };

  return (
    <div className="contact-form" id="contact">
      <div className='contact-form-wrapper'>
        <div className='contact-details-wrapper'>
          <h2>Get in touch today</h2>
          <p>Reach out to Key Developments to start your project today.</p>
          <p>We offer a no-obligation quotation service.</p>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="form-item-wrapper">
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Name"
              required
            />
          </div>
          <div className="form-item-wrapper">
            <input
              type="text"
              id="number"
              name="number"
              value={formData.number}
              onChange={handleChange}
              placeholder="Phone number"
              required
            />
          </div>
          <div className="form-item-wrapper">
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email"
              required
            />
          </div>
          <div className="form-item-wrapper">
            <select id="subject" name="subject" value={formData.subject} onChange={handleChange} required>
              <option value="Renovations">Renovations</option>
              <option value="Extensions">Extensions</option>
              <option value="Kitchens">Kitchens</option>
              <option value="Bathrooms">Bathrooms</option>
              <option value="Garden">Garden</option>
              <option value="Commercial">Commercial</option>
            </select>
          </div>
          <div className="form-item-wrapper">
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="Message"
              required
            />
          </div>
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
