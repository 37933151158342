import React, { useState } from 'react';
import './Gallery.css';

const importAll = (requireContext) => {
  try {
    return requireContext.keys().map(requireContext);
  } catch (error) {
    console.error('Error importing images:', error);
    return [];
  }
};

const images = importAll(require.context('../images', false, /\.(png|jpe?g|JPEG|svg)$/)).map(image => image.default || image);

const Gallery = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const prevImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <div className="gallery" id="gallery">
      {images.length > 0 ? (
        <>
        <div className="gallery-container">
          <img src={images[currentIndex]} alt={`Gallery ${currentIndex}`} />

          <button className="nav-button left" onClick={prevImage}>&#9664;</button>
          <button className="nav-button right" onClick={nextImage}>&#9654;</button>
        </div>
        <img className='blurred-background' src={images[currentIndex]} alt={`Gallery ${currentIndex}`} />
        </>
      ) : (
        <p>No images to display</p>
      )}
    </div>
  );
};

export default Gallery;
