import React, { useState } from 'react';
import './Hero.css';
import logo from '../Key Developments Logo.png';

const Hero = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="hero">
      <div className="hero-overlay"></div>
      <div className="hero-content-wrapper">
        <img src={logo} alt="Key Developments Logo" className="logo" />
        <nav className="nav">
          <ul className="desktop-menu">
            <li><a href="#important-work">Our Work</a></li>
            <li><a href="#work-overview">Overview</a></li>
            <li><a href="#gallery">Gallery</a></li>
            <li><a href="#contact">Contact</a></li>
          </ul>
          <button className="mobile-menu-button" onClick={toggleMenu}>
            &#9776;
          </button>
        </nav>
        {menuOpen && (
          <div className="mobile-menu">
            <button className="close-button" onClick={toggleMenu}>
              &times;
            </button>
            <ul>
              <li><a href="#important-work" onClick={toggleMenu}>Our Work</a></li>
              <li><a href="#work-overview" onClick={toggleMenu}>Overview</a></li>
              <li><a href="#gallery" onClick={toggleMenu}>Gallery</a></li>
              <li><a href="#contact" onClick={toggleMenu}>Contact</a></li>
            </ul>
          </div>
        )}
        <div className="hero-content">
          <h1>Transform your home</h1>
          <p>End-to-end project management specialists</p>
          <a href="#contact" className="cta-button">Get in Touch</a>
        </div>
      </div>
    </div>
  );
};

export default Hero;
