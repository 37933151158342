import React from 'react';
import Hero from './components/Hero';
import ImportantWork from './components/ImportantWork';
import WorkOverview from './components/WorkOverview';
import Gallery from './components/Gallery';
import ContactForm from './components/ContactForm';
import './fonts.css';
import './App.css';

function App() {
  return (
    <div className="App">
      <Hero />
      <ImportantWork />
      <WorkOverview />
      <Gallery />
      <ContactForm />
    </div>
  );
}

export default App;
